export interface IDictionary{
    name : string;
    code : string;
    value : string;
    sequence : number;
    extendField0 : string;
    extendField1 : string;
    extendField9 : string;
    id : number;
    children:IDictionary[];
}

export class dictionary implements IDictionary{
    name = '';
    code = '';
    value = '';
    sequence = 0;
    extendField0 = '';
    extendField1 = '';
    extendField9 = '';
    id = 0;
    children:dictionary[] = [];
}