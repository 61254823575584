import axiosHttpService from '@/core/utils/axios';
import { IDictionary } from './dtos/dictionary';

export class DictionaryService {
    /**
     * 获取证件类型列表
     * 1:幼儿证件类型 （参数不传默认为1）2：监护人证件类型
     * */
    getZjlxList(type?: number) {
        if (!type) {
            type = 1;
        }
        return axiosHttpService.get<Array<IDictionary>>({
            url: 'Dictionary/GetZjlxList',
            params: { type }
        });
    }

    /**
     * 查询区 街道 居委
     * 区code（参数为空则查询所有区）
     * */
    getJuweiList(areaCode: string) {
        return axiosHttpService.get<Array<IDictionary>>({
            url: 'Dictionary/GetJuweiList',
            params: { areaCode }
        });
    }

    /**
     * 获得民族列表
     * */
    getMZList() {
        return axiosHttpService.get<Array<IDictionary>>({
            url: 'Dictionary/GetMZList'
        })
    }

    /**
     * 获得监护人与幼儿关系列表
     * */
    getRelationList() {
        return axiosHttpService.get<Array<IDictionary>>({
            url: 'Dictionary/GetRelationList'
        })
    }


}

export default new DictionaryService();